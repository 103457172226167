import React from "react";
import { Container, CenterWrapper } from "app/components/RouteComponents";
import { Helmet } from "react-helmet-async";
import ProgramSearch from "app/components/Algolia/ProgramSearch";
import { FilterIconButton } from "app/components/Button/IconButton/FilterIconButton";
import Flex from "app/components/Flex";
import ScrollToTopOnMount from "app/components/ScrollToTopOnMount";
import { ScreenLtMd } from "app/components/MediaQueries";
import { H1 } from "app/components/Typography";
import { useParams } from "react-router";
import { AlgoliaIndexes } from "app/components/Algolia/types";

const Programs = () => {
  const { categorySlug } = useParams();

  return (
    <CenterWrapper>
      <Helmet title="Programs | Online Dance Classes and Tutorials" />
      <ScrollToTopOnMount />
      <ScreenLtMd>
        {matches => (
          <Container px={[3, 3, 4]}>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mb={[3, 3, 4]}
            >
              <H1>Programs</H1>
              {!matches && (
                <FilterIconButton
                  indexName={AlgoliaIndexes.programs}
                  color="monochrome.9"
                />
              )}
            </Flex>
            <ProgramSearch
              refinements={{
                categories: categorySlug,
              }}
            />
          </Container>
        )}
      </ScreenLtMd>
    </CenterWrapper>
  );
};

export default Programs;
